import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../theme/colors';
import { HarvoxxSchool } from '../../theme/text';
import { LeftBar } from '../../Components/sidebar';
import Header from '../components/header';
import { Card, SearchCard } from '../components/cards';
import { connect } from 'react-redux';
import { User, View_student } from '../../redux/state/action';
import { GetUser } from '../../api-services/users';
import { Notify } from '../../utility';
import { ToastContainer } from 'react-toastify';


const Colors = Color()

const Dashboard = ({ appState, disp_user, disp_View_student }) => {
    const User = appState.User;
    const navigate = useNavigate();
    const [loading, setloading] = React.useState(false)
    const [text, settext] = React.useState("")
    const [announcement, setannouncement] = React.useState("")

    useEffect(() => {
        // console.log(User)
        GetUser(User.UUID)
            .then(response => {
                disp_user(response.data)
                // Notify("Status updated")
                // console.log(response)
            })
            .catch(error => {
                Notify("An error occured ", "error")
            })
    }, []);



    // return User == null ? navigate("/signin") : (
    return (
        <div  >
            <ToastContainer />
            {/* {console.log(User)} */}
            <LeftBar Navigate={navigate} User={User} disp_user={disp_user} page="HOME" />

            <div style={{
                backgroundColor: Colors.white,
                position: "relative",
                width: "80%",
                // height: "100vh",
                left: "20%",
                paddingBottom: 50,
            }} >
                <Header
                    loading={loading}
                    setloading={setloading}
                    text={text}
                    settext={settext}
                    Navigation={navigate}
                    disp_View_student={disp_View_student}
                />

                {/* cards */}
                <div style={{
                    marginTop: 10
                }} >
                    <Card User={User}  navigation={navigate} />
                </div>

                <SearchCard page="DASHBOARD" User={User} disp_user={disp_user} data={announcement} setData={setannouncement} />
                {/* <div> 
                    <div style={{
                        height: 300,
                        width: 200,
                        backgroundColor: "red",
                        position: "relative",
                        left: 20,
                        top: 20
                    }} />
                    <div style={{
                        height: 300,
                        width: 200,
                        backgroundColor: "red",
                        position: "relative",
                        left: 20,
                        top: 20
                    }} />
                    <div style={{
                        height: 300,
                        width: 200,
                        backgroundColor: "red",
                        position: "relative",
                        left: 20,
                        top: 20
                    }} />
                </div> */}

            </div>

            {/* SearchCard */}
            {/* <SearchCard /> */}


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
        disp_View_student: (payload) => dispatch(View_student(payload)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
