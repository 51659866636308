import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, colors } from '@mui/material';
import { Color } from '../../theme/colors';
import { CreateAnnouncementModel } from '../../api-services/announcement';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Badge } from '../../theme/text';
import { CreateAttendanceModel } from '../../api-services/attendance';
import { GetUser } from '../../api-services/users';
import { Notify } from '../../utility';


const notify = (message, state) => {
    // toast("Default Notification !");

    if (state) {
        toast.error(message, {
            position: toast.POSITION.TOP_Left
        });
    } else {
        toast.success(message, {
            position: toast.POSITION.TOP_Left
        });
    }

    // toast.error("Error Notification !", {
    //     position: toast.POSITION.TOP_LEFT
    // });

    // toast.warn("Warning Notification !", {
    //     position: toast.POSITION.BOTTOM_LEFT
    // });

    // toast.info("Info Notification !", {
    //     position: toast.POSITION.BOTTOM_CENTER
    // });

    // toast("Custom Style Notification with css class!", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: 'foo-bar'
    // });
};


const Colors = Color()
export function CreateAttendance({ User, data, setData, disp_user }) {
    let [loading, setLoading] = React.useState(false)
    const Create = () => {
        if (User.attendance.filter(e => e.isActive == true).length > 0) {
            notify("You cannot create a new attendance until you deactivate the current one.", "error")
        } else {
            setLoading(true)
            CreateAttendanceModel({
                program: User.program, packageID: User.packageID
            })
                .then(res => {
                    if (res.success == true) {

                        GetUser(User.UUID)
                            .then(response => {
                                setLoading(false)
                                disp_user(response.data)
                                Notify("Status updated")
                            })
                            .catch(error => {
                                setLoading(false)
                                Notify("An error occured", "error")
                            })


                    }
                })
                .catch(error => {
                    console.log(error)
                    setLoading(false)
                })
        }

    }
    // console.log(User)

    const today = new Date();
    let dayOfWeekNumber = today.getDay();

    // Adjusting the numbering to start from Monday (0 for Monday, 1 for Tuesday, etc.)
    dayOfWeekNumber = (dayOfWeekNumber + 6) % 7;

    let filter = User.trainingDays.filter(e => e.code == dayOfWeekNumber)

    return (
        <Card sx={{ maxWidth: 345, my: 7 }}>
            <ToastContainer />
            <center style={{
                height: 120,
                backgroundColor: "#FBF6E9",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column"
            }} >
                <Badge value="Create attendance for class number" color="grey" />
                <Badge color={Colors.secondary} size={30} value={User.attendance.length + 1} />
            </center>
            <CardActions>
                <center style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                    {loading ?
                        <CircularProgress style={{ marginTop: 10 }} size={18} /> :
                        <Button

                            onClick={() => {
                                // Get the current date and time
                                const currentDate = new Date();
                                // Get the current hour and minute
                                const currentHour = currentDate.getHours();
                                const currentMinute = currentDate.getMinutes();

                                if (User.attendance.filter(e => e.isActive == true).length > 0) {
                                    return notify("You cannot create a new attendance until you deactivate the current one.", "error")
                                }
                                function getCurrentDate(dateString) {
                                    // Parse the date string into a Date object
                                    const currentDate = new Date(dateString);

                                    // Get the current hour
                                    const currentHour = currentDate.getUTCHours();

                                    return currentDate;
                                }

                                //   check if there was an attendace created today
                                let createdToday = User.attendance.filter(e => e.date.split("T")[0] == new Date().toISOString().split("T")[0])

                                if (createdToday.length > 0) {
                                    return Notify(`You have already authenticated all your students for today`, "error")
                                }

                                if (filter.length > 0) {
                                    // console.log(currentHour - filter[0].start.hr)
                                    if (currentHour - filter[0].start.hr < -1 || currentHour - filter[0].start.hr < 1) {
                                        Create()
                                    } else {
                                        let startHr = filter[0].start.hr;
                                        let startHrPlus = filter[0].start.hr + 1;
                                        let AmPm = "";
                                        if (startHr < 11) {
                                            AmPm = "am"
                                        } else {
                                            AmPm = "pm"
                                        }
                                        Notify(`You can only create attendance between ${startHr}:00 and ${startHrPlus}:00`, "error")

                                    }
                                    // Create()

                                } else {
                                    Notify("You have no class today", "error")
                                }
                            }}
                            style={{ backgroundColor: filter.length < 1 ? "lightgrey" : Colors.primary, width: 300, padding: "10px 0px", color: "white" }}  >
                            Create Attendance</Button>

                    }
                </center>
            </CardActions>
        </Card>
    );
}