import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from "react-modal";
import { Supabase } from "../config/supabase-config";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Color } from '../theme/colors';
import { HarvoxxSchool } from '../theme/text';
import { connect } from 'react-redux';
import { User } from '../redux/state/action';
import { LoginModel, SignUpModel } from '../api-services/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Notify } from '../utility';
import { ToastContainer } from 'react-toastify';


const Colors = Color()

const Signin = ({ disp_user, appState }) => {
  const User = appState.User;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [name, setName] = React.useState("")
  const [Package, setpackage] = React.useState('');

  const handleChange = (event) => {
    setpackage(event.target.value);
  };



  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");



  useEffect(() => {

  }, []);



  const Register = () => {
    setLoading(true)
    if (name && email && email.length > 4 || Package) {
      SignUpModel({ email, name, phone, Package })
        .then(response => {
          if (response.success == false) {
            Notify(response.message, "error")
          } else {
            Notify("Account created successfully")
          }
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }



  return User != null ? navigate("/dashboard") : (
    <div className='signup-page'>
      <ToastContainer />
      <Header />
      <section className='signup'>
        <div className='left'>
          <HarvoxxSchool />
          <h1 style={{ marginTop: 30 }}>
            <p>
              Manage students, assign tasks, review tasks, make announcement, create authentication code, and lots more.
            </p>
          </h1>

        </div>
        <div className='right' >

          <div className='form' style={{
            backgroundColor: Colors.primary,
            padding: 20
          }}>
            <p style={{ marginBottom: 19, color: "white" }}>
              Register students
            </p>

            <input type="text" name="" id="" placeholder='name'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }} />

            <input type="email" name="" id="" placeholder='Email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }} />

            <input type="tel" name="" id="" placeholder='Phone'
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value)
              }} />

            <FormControl sx={{ minWidth: "100%", backgroundColor: "#fff", borderRadius: 3, color: "red", outline: "none" }}>
              <InputLabel id="demo-simple-select-autowidth-label">Package</InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={Package}
                onChange={handleChange}
                autoWidth
                label="Package"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Website development (Frontend)">Website development (Frontend)</MenuItem>
                <MenuItem value="Graphics Design / Product Branding">Graphics Design / Product Branding</MenuItem>
                <MenuItem value="Cyber Security">Cyber Security</MenuItem>
                <MenuItem value="Data Analytics">Data Analytics</MenuItem>
                <MenuItem value="Product Design">Product Design</MenuItem>
              </Select>
            </FormControl>


            <LoadingButton loading={loading} variant="contained"
              loadingPosition="start"
              load
              size='small'
              style={{ cursor: 'pointer', backgroundColor: Colors.secondary, height: 40, width: "100%", marginTop: 30 }}
              onClick={() => {
                Register()
              }}
            >Register</LoadingButton>




          </div>
        </div>
      </section>



    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appState: state.user,
  };
};


const mapDispatchToProps = (dispatch, encoded) => {
  return {
    disp_user: (payload) => dispatch(User(payload)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Signin);
