import React from 'react';
import {CgProfile} from 'react-icons/cg';
import logo from '../images/logo.png'

const Header = () => {
  return (
    <div>
        <header>
            <nav>
                <div className="logo">
                    {/* <h3>OPOBO <span>HERITAGE FESTIVAL</span></h3> */}
                    <img src='https://school.harvoxx.com/static/media/logo.6ba0579820bd9bee66d8.png' width={140} />
                </div>

                {/* <ul>
                    <li><a href="">Sign Up</a></li>
                    <CgProfile className='prof'/>
                </ul> */}
            </nav>
        </header>
    </div>
  )
}

export default Header
