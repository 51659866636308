import React from 'react';
import { CgProfile } from 'react-icons/cg';
import { SearchStudents } from './searchinput';
// import logo from '../images/logo.png'

const Header = ({
  loading, setloading, text, settext, Navigation, disp_View_student
}) => {
  return (
    <div style={{ paddingTop: 10 }} >
      <header>
        <nav>
          <div className="logo">
            {/* <h3>OPOBO <span>HERITAGE FESTIVAL</span></h3> */}
            <img src='https://school.harvoxx.com/static/media/logo.6ba0579820bd9bee66d8.png' width={140} />
          </div>

          <ul>
            <SearchStudents
              loading={loading}
              setloading={setloading}
              text={text}
              settext={settext}
              Navigation={Navigation}
              disp_View_student={disp_View_student}
            />
          </ul>
        </nav>
      </header>
    </div>
  )
}

export default Header
