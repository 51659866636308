import { Supabase } from "../../config/supabase-config";
import { BaseURL } from "../../utility";

export async function LoginModel({
    email, password
}) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "email": email,
        "password": password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}auth/admin-login`, requestOptions);
        const result_1 = await response.text();
        // console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}


export async function SignUpModel({ email, name, phone, Package }) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "name": name,
        "phone": phone,
        "email": email,
        "program": "DSP300-C3",
        "package": Package,
        "starting": "Jan. 2024",
        "ending": "Apr 2024",
        "trainingSchedule": null,
        "packageType": "FREE"
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}auth/signup`, requestOptions);
        const result_1 = await response.text();
        // console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}