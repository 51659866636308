import { BaseURL } from "../../utility";

export async function CreateAnnouncementModel({
    message, program, packageID
}) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "message":message,
        "program": program,
        "packageID": packageID
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}admin/create-announcement`, requestOptions);
        const result_1 = await response.text();
        // console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}