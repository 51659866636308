import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { HomeIcon, Announcement, TaskIcon, AttendanceIcon } from '../../utility/icon';
import { Badge } from '../../theme/text';
import { Button, CardActions, CircularProgress, Divider, Fade, Modal, Typography } from '@mui/material';
import { CreateAnnouncement } from './create-announcement';
import { GroupOutlined, Label } from '@mui/icons-material';
import { Notify, formatDate } from '../../utility';
import { Color } from '../../theme/colors';
import { CreateAttendance } from './creat-attendance';
import { LoadingButton } from '@mui/lab';
import { UpdateAttendanceStatus } from '../../api-services/attendance';
import { ToastContainer } from 'react-toastify';
import { GetUser } from '../../api-services/users';
import QRCode from "react-qr-code";
import Backdrop from '@mui/material/Backdrop';
import { CreateTaskModel } from '../../api-services/task';
import { useNavigate } from 'react-router';

const Colors = Color()




export function TaskLists({ User, data, setData, disp_user, page }) {
    let [viewData, setViewData] = React.useState(null)
    let [loading, setLoading] = React.useState(false)
    let [date, setDate] = React.useState("")
    let [title, setTitle] = React.useState("")
    const navigate = useNavigate();


    function Card({ item }) {
        return (

            <div
                onClick={() => {
                    setViewData(item)
                    console.log(item)
                }}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: item.isActive ? "#f9f9f9" : "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 13px",
                    marginBottom: 6,
                    cursor: "pointer"

                }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "green",
                    flex: 2
                }}>
                    <Badge value={item.title} size={15} color="#000" />
                    <Badge value={item.task} size={12} color="grey" />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "green",
                    flex: 1
                }}>
                    <Badge value={item.deadline} size={12} color="red" />

                </div>
            </div>
        );
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    function CreateTask() {
        setLoading(true)
        CreateTaskModel({ program: User.program, packageID: User.packageID, title, deadline: date, task: data })
            .then(response => {
                GetUser(User.UUID)
                    .then(response => {
                        setLoading(false)
                        disp_user(response.data)
                        Notify("Task created")
                    })
                    .catch(error => {
                        setLoading(false)
                        Notify("An error occured", "error")
                    })

            })
            .catch(error => {
                Notify("An error occured", "error")
                setLoading(false)
            })
    }

    return (
        <>
            <ToastContainer />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        width: 200,
                        height: 108,
                    },
                    justifyContent: "space-between",
                    marginBottom: 30

                }}
            >

                <div style={{
                    // height: 500,
                    width: "57%",
                    // backgroundColor: "#F2F1F3",
                    position: "relative",
                    left: 20,
                    top: 20,
                    padding: 10,

                }}>
                    <Badge value="Create task" color="grey" />

                    <div style={{
                        marginTop: 20
                    }}>
                        <ToastContainer />
                        <textarea
                            value={data}
                            onChange={(e) => {
                                setData(e.target.value);
                            }}
                            placeholder='What task do you have for your students?'
                            style={{
                                height: 100,
                                width: "92%",
                                border: "0.5px solid grey",
                                padding: 20,
                                outline: "none",
                                resize: "none",
                                marginBottom: 20

                            }} ></textarea>
                        <input name="" id="" placeholder='Task title'
                            style={{
                                width: "55%",
                                outline: "none",
                                padding: "10px 6px",
                                borderRadius: 4,
                                border: "0.5px solid grey",
                            }}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }} />
                        <input type="date" id="" placeholder='Task title'
                            style={{
                                // width: "70%",
                                outline: "none",
                                padding: "10px 6px",
                                borderRadius: 4,
                                border: "0.5px solid grey",
                                marginLeft: 10,
                            }}
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value);
                            }} />



                        <CardActions>

                            {loading ?
                                <div style={{ display: "flex", paddingLeft: "10%", alignItems: "center", width: '100%', marginTop: 20 }} >
                                    <CircularProgress size={18} />
                                </div>
                                :
                                <Button
                                    onClick={() => {
                                        if (data.length > 0) {
                                            CreateTask()
                                        }
                                    }}
                                    style={{ backgroundColor: data.length < 1 ? "lightgrey" : Colors.primary, width: 170, padding: "10px 0px", color: "white", marginTop: 20 }}  >
                                    Send task
                                </Button>
                            }
                        </CardActions>

                        {
                            viewData != null &&
                            <>
                                <Divider style={{ marginTop: 30 }} />
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // marginTop: 30,
                                    padding: 15
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                    }}>
                                        <Badge color="grey" size={13} value="Created" />
                                        <Badge color="#000" size={14} value={formatDate(viewData.date)} />
                                    </div>



                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        textAlign: "center"
                                    }}>
                                        <Badge color="grey" size={13} value="Submitted" />
                                        <Badge color="#000" size={14} value={viewData.submitted.length} />
                                        {viewData.submitted.length > 0 && <Button
                                            onClick={() => {
                                                navigate(`/submitted-task/${viewData.id}`)

                                            }} style={{ fontSize: 11 }} variant="text">View</Button>}

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="grey" size={13} value="Task status" />
                                        <Badge color="#000" size={14} value={viewData.isActive == true ? "ACTIVE" : "INACTIVE"} />
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="#000" size={14} value={formatDate(viewData.deadline)} />
                                        <Badge color="grey" size={13} value="Deadline" />
                                    </div>


                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "16px 20px",
                                    // textAlign:"center"
                                }}>
                                    <Badge color="#000" size={14} value={viewData.title} />
                                    <Badge color="grey" size={13} value={viewData.task} />
                                </div>
                            </>
                        }

                    </div>
                </div>



                {/* timetable */}
                <div style={{
                    // height: 130,
                    width: "30%",
                    // backgroundColor: "#F2F1F3",
                    position: "relative",
                    right: 20,
                    top: 20,
                    borderRadius: 10,

                }} >
                    {
                        // console.log(User)
                        User.Tasks.map((item, index) => {
                            const givenDateString = item.deadline;
                            const givenDate = new Date(givenDateString);
                            const currentDate = new Date();
                           
                            if(givenDate >= currentDate){
                                return <Card item={item} />
                            }
                            // return <Card item={item} />
                        })
                    }
                </div>

            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <center>
                            <QRCode
                                size={256}
                                style={{ height: 290, maxWidth: "100%", width: "100%" }}
                                value={viewData && viewData.code}
                                viewBox={`0 0 256 256`}
                            />



                        </center>
                    </Box>
                </Fade>
            </Modal>

        </>
    )
}