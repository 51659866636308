import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { HomeIcon, Announcement, TaskIcon, AttendanceIcon } from '../../utility/icon';
import { Badge } from '../../theme/text';
import { Button, CircularProgress, Divider, Fade, Modal, Typography } from '@mui/material';
import { CreateAnnouncement } from './create-announcement';
import { ArrowCircleRightOutlined, Delete, GroupAddOutlined, GroupOutlined, Label, Refresh } from '@mui/icons-material';
import { Notify, formatDate } from '../../utility';
import { Color } from '../../theme/colors';
import { CreateAttendance } from './creat-attendance';
import { LoadingButton } from '@mui/lab';
import { UpdateAttendanceStatus } from '../../api-services/attendance';
import { ToastContainer } from 'react-toastify';
import { GetUser, GetUserByEmail_Phone_AdmissionNo_Model } from '../../api-services/users';
import QRCode from "react-qr-code";
import Backdrop from '@mui/material/Backdrop';

const Colors = Color()

export function Card({ User, navigation }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    // m: 2,
                    width: 200,
                    height: 90,
                },
                justifyContent: "space-evenly",
                // backgroundColor: "#F2F1F3",


            }}
        >
            <Paper onClick={() => {
                navigation("/students")
            }} style={{ backgroundColor: "#F2F1F3", display: "flex", flexDirection: "row", cursor: "pointer" }} elevation={1}>
                <div style={{ height: "100%", flex: 1, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", }}>
                    <GroupAddOutlined style={{
                        color: Colors.secondary,
                        fontSize: 40
                    }} />
                </div>

                <div style={{ height: "100%", flex: 2, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }} >
                    <Badge size={20} value={User.students.length} color="#000" />
                    <Badge value="Total students" color="grey" />
                </div>
            </Paper>

            <Paper style={{ backgroundColor: "#F2F1F3", display: "flex", flexDirection: "row" }} elevation={1}>
                <div style={{ height: "100%", flex: 1, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", }}>
                    <TaskIcon />
                </div>

                <div style={{ height: "100%", flex: 2, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }} >
                    <Badge size={20} value={User.Tasks.length} color="#000" />
                    <Badge value="Total tasks" color="grey" />
                </div>
            </Paper>

            <Paper style={{ backgroundColor: "#F2F1F3", display: "flex", flexDirection: "row" }} elevation={1}>
                <div style={{ height: "100%", flex: 1, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", }}>
                    <AttendanceIcon />
                </div>

                <div style={{ height: "100%", flex: 2, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }} >
                    <Badge size={20} value={User.attendance.length} color="#000" />
                    <Badge value="classes held" color="grey" />
                </div>
            </Paper>
            <Paper style={{ backgroundColor: "#F2F1F3", display: "flex", flexDirection: "row" }} elevation={1}>
                <div style={{ height: "100%", flex: 1, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", }}>
                    <Announcement />
                </div>

                <div style={{ height: "100%", flex: 2, backgroundColor: " ", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }} >
                    <Badge size={20} value={User.Announcements.length} color="#000" />
                    <Badge value="Announcements" color="grey" />
                </div>
            </Paper>
        </Box>
    );
}

export function SearchCard({ User, data, setData, disp_user, page }) {
    let [viewData, setViewData] = React.useState(null)
    let [loading, setLoading] = React.useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <ToastContainer />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        width: 200,
                        height: 108,
                    },
                    justifyContent: "space-between",

                }}
            >

                <div style={{
                    // height: 420,
                    width: "57%",
                    backgroundColor: "#F2F1F3",
                    position: "relative",
                    left: 20,
                    top: 20,
                    padding: 10,

                }}>
                    {User.attendance.length > 0 && <Badge value={`${User.package} attendance`} color="grey" />}

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                // m: 2,
                                width: 200,
                                height: 90,
                            },
                            marginTop: 2,
                            paddingLeft: 1,
                            // justifyContent: "space-evenly",
                            // backgroundColor: "green"

                        }}
                    >
                        {

                            User.attendance.map((item, index) => {
                                return <div key={index}
                                    onClick={() => {
                                        setViewData(item);
                                        // console.log(item)
                                    }}
                                    style={{
                                        height: 55, backgroundColor: item.isActive == false ? "#fff" : "lightgrey",
                                        justifyContent: "center",
                                        alignItems: "center", display: "flex",
                                        flexDirection: "column", width: 80,
                                        borderRadius: 5, margin: "7px 20px",
                                        cursor: "pointer"
                                    }} >
                                    <Badge size={14} value={formatDate(item.date)} color="grey" />
                                    <div style={{
                                        marginTop: 5,
                                        display: "flex", flexDirection: "row",
                                        justifyContent: "space-evenly", alignItems: "center",
                                        borderTop: `1px solid ${item.isActive == false ? "lightgrey" : Colors.white}`, width: "100%"
                                    }}>
                                        <GroupOutlined style={{ color: "grey", marginTop: 2, fontSize: 17 }} />
                                        <Badge size={13} value={item.students.length} color="#000" />
                                    </div>
                                </div>
                            })
                        }

                    </Box>

                    {/* empty */}
                    {User.attendance.length < 1 && <>

                        <center style={{
                            marginTop: 70
                        }}>
                            <Badge value="No attendance created yet" size={20} color="#000" />

                            {/*<AttendanceIcon size={170} color="grey" /> */}
                            <CreateAttendance disp_user={disp_user} User={User} data={data} setData={setData} />
                        </center>
                    </>}

                    {
                        viewData != null &&
                        <>
                            <div style={{
                                marginBottom: 60
                            }} >

                                <Divider style={{ marginTop: 30 }} />
                                <div style={{
                                    // height: 120,
                                    // backgroundColor: "#FBF6E9",
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                    // marginTop: 30,
                                    padding: 15
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                    }}>
                                        <Badge color="grey" size={13} value="Created" />
                                        <Badge color="#000" size={14} value={formatDate(viewData.date)} />
                                    </div>



                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="grey" size={13} value="No. of students" />
                                        <Badge color="#000" size={14} value={viewData.students.length} />
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="grey" size={13} value="Attendance status" />
                                        <Badge color="#000" size={14} value={viewData.isActive == true ? "ACTIVE" : "INACTIVE"} />
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="grey" size={13} value="Action" />
                                        {viewData.isActive == true ?
                                            <>
                                                {loading ?
                                                    <CircularProgress style={{ marginTop: 10 }} size={18} /> :


                                                    <Button
                                                        onClick={() => {
                                                            setLoading(true)
                                                            UpdateAttendanceStatus({ status: false, program: User.program, packageID: User.packageID, attendanceID: viewData.code })
                                                                .then(response => {
                                                                    if (response.success == true) {
                                                                        GetUser(User.UUID)
                                                                            .then(response => {
                                                                                setLoading(false)
                                                                                disp_user(response.data)
                                                                                setViewData({ ...viewData, isActive: false })
                                                                                Notify("Status updated")
                                                                                handleClose()
                                                                            })
                                                                            .catch(error => {
                                                                                setLoading(false)
                                                                                Notify("An error occured", "error")
                                                                            })
                                                                        // get the index of viewData 
                                                                        // Notify("Status updated")
                                                                    }

                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    setLoading(false)
                                                                })

                                                        }}
                                                        style={{ marginTop: 4, fontSize: 10 }}
                                                        size="small" loading variant="contained" color="error">
                                                        Deactivate
                                                    </Button>


                                                }
                                            </>


                                            :
                                            <Button
                                                disabled
                                                style={{ marginTop: 4, fontSize: 10 }}
                                                size="small" variant="contained"  >
                                                Activate
                                            </Button>}
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "6px 20px",
                                        // textAlign:"center"
                                    }}>
                                        {/* <Badge color="grey" size={13} value="Action" /> */}
                                        <QRCode
                                            onClick={handleOpen}
                                            size={256}
                                            style={{ height: "40", maxWidth: "100%", width: "100%", marginTop: 6 }}
                                            value={viewData && viewData.code}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>


                                </div>

                                <div style={{ marginBottom: 30 }} >
                                    {viewData.students.map((items, index) => {
                                        return <div key={index} style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: "6px 20px",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                margin: "6px 20px",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <Badge color="#000" size={12} value={index + 1} />
                                                <div style={{ marginLeft: 10 }}>
                                                    <Badge color="#000" size={15} value={items.name} />
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                                <Badge color={Colors.primary} size={12} value={items.admissionNo} />
                                            </div>
                                        </div>
                                    })}
                                </div>

                            </div>

                        </>
                    }
                </div>



                {/* timetable */}
                <div style={{
                    height: 120,
                    width: "30%",
                    // backgroundColor: "#F2F1F3",
                    backgroundColor: Colors.secondary,
                    position: "relative",
                    right: 20,
                    top: 20,
                    // borderRadius: 10,
                    padding: 2,
                }} >
                    {/* <Badge value="Timetable" color="#000" /> */}

                    {
                        User.trainingDays.map((item, index) => {
                            const today = new Date();
                            let dayOfWeekNumber = today.getDay();

                            // Adjusting the numbering to start from Monday (0 for Monday, 1 for Tuesday, etc.)
                            dayOfWeekNumber = (dayOfWeekNumber + 6) % 7;
                            // console.log(item)
                            return <>
                                <div style={{
                                    height: "50%",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: item.code == dayOfWeekNumber ? "#F2F1F3" : "#fff"
                                }}>
                                    <div style={{ height: "100%", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex", }}>
                                        <span style={{ fontSize: 13 }}>{item.time.split("-")[0]}</span>
                                        <span style={{ fontSize: 10 }} >-</span>
                                        <span style={{ fontSize: 13 }}>{item.time.split("-")[1]}</span>
                                    </div>
                                    <Divider orientation='vertical' style={{ color: "white" }} />
                                    <div style={{ height: "100%", flex: 2, backgroundColor: " ", justifyContent: "center", paddingLeft: 10, alignItems: "flex-start", display: "flex", flexDirection: "column" }} >
                                        <Badge value={item.day} color="grey" />
                                        <Badge size={15} value={item.package} color="#000" />
                                    </div>
                                </div>

                                <Divider style={{ color: "white" }} />
                            </>
                        })
                    }




                    {page == "DASHBOARD" && <CreateAnnouncement disp_user={disp_user} User={User} data={data} setData={setData} />}
                    {page == "ATTENDANCE" && <CreateAttendance disp_user={disp_user} User={User} data={data} setData={setData} />}
                </div>

            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <center>
                            <QRCode
                                size={256}
                                style={{ height: 290, maxWidth: "100%", width: "100%" }}
                                value={viewData && viewData.code}
                                viewBox={`0 0 256 256`}
                            />



                        </center>
                    </Box>
                </Fade>
            </Modal>

        </>
    )
}


export function AllStudentsCard({ User, disp_View_student, Navigation, Data, disp_user }) {
    let [viewData, setViewData] = React.useState(null)

    let [Emails, setEmails] = React.useState([])

    let [RetreivedUser, setRetreivedUser] = React.useState(null)



    const GetUserByEmail_Phone_AdmissionNo_controller = (text) => {

        GetUserByEmail_Phone_AdmissionNo_Model(text)
            .then(response => {
                if (response.success == false) {
                    setViewData(null)
                    return Notify(response.message, "error")
                }
                else if (!response.data) {
                    setViewData(null)
                    return Notify(response.message, "error")
                } else {

                    // disp_View_student(response.data)
                    // Navigation("/single-student")
                    // setViewData(null)
                    // setRetreivedUser(response.data)

                    // filter all students to get the exact student
                    let filter = User.students.filter(e => e.admision_no == text)
                    // get index
                    let FindIndex = User.students.findIndex(e => e.admision_no == text)

                    if (filter.length > 0) {
                        let newUserData = {
                            ...filter[0],
                            attendance: response.data.attendance
                        }
                        User.students.splice(FindIndex, 1, newUserData)
                        disp_user({ ...User })
                    }
                    // console.log(response.data.attendance)
                    // console.log(filter)
                    // console.log(FindIndex)


                    setViewData(null)
                }
            })
            .catch(error => {
                Notify("A network error occured", "error")
                setViewData(null)
            })
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <ToastContainer />
            <Box
            >

                <div style={{
                    // height: 420,
                    // width: "57%",
                    // backgroundColor: "#F2F1F3",
                    // position: "relative",
                    left: 20,
                    top: 20,
                    padding: 10,

                }}>
                    <Badge value={`${User.package} students`} color="grey" />

                    {User.students.map((items, index) => {
                        return <div

                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "6px 20px",
                                justifyContent: "space-between",
                                alignItems: "center",

                                backgroundColor: items.phone == viewData ? "#fff" : items.hasAccess == true ? "#f6f6f6" : "#ffb0bf",
                                borderRadius: 3,
                                padding: 5
                            }}>
                            <Badge color="#000" size={15} value={items.email} />
                            {/* <div
                              
                                style={{
                                    backgroundColor: "grey",
                                    cursor: "pointer",
                                }} > */}
                            <div
                                onClick={() => {
                                    // Emails.push(items.email)
                                    // setEmails(Emails)
                                    // console.log(Emails)
                                    setViewData(items.phone)
                                    GetUserByEmail_Phone_AdmissionNo_controller(items.admision_no)
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    margin: "6px 20px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}>
                                <Badge color="#000" size={12} value={index + 1} />
                                <div style={{ marginLeft: 10 }}>
                                    <Badge color="#000" size={15} value={items.name} />
                                </div>


                                <div style={{ marginLeft: 10 }}>
                                    {/* <Badge color={Colors.primary} size={12} value={items.phone} /> */}
                                    {items.phone == viewData ?
                                        <CircularProgress size={20} /> :
                                        <b style={{
                                            fontSize: 10,
                                            backgroundColor: Colors.primary,
                                            padding: "2px 6px",
                                            borderRadius: 5,
                                            color: "#fff"
                                        }} >{items.admision_no.slice(-3)}</b>
                                    }
                                </div>
                            </div>

                            {/* </div> */}

                            <div style={{ marginLeft: 10, flexDirection: "row", display: "flex", alignItems: "center" }}>
                                {
                                    items.phone != viewData &&
                                    <>
                                        {
                                            items.attendance && items.attendance.map((items, index) => {

                                                return <div key={index} style={{
                                                    height: "10px", width: "10px",
                                                    backgroundColor: items.attended == true ? "mediumseagreen" : "maroon",
                                                    borderRadius: "2px", margin: "0px 2px"
                                                }} />
                                            })
                                        }

                                        {items.attendance &&
                                            <div style={{ marginLeft: 20 }} >
                                                <ArrowCircleRightOutlined
                                                    onClick={() => {
                                                        disp_View_student(items)
                                                        Navigation("/single-student")
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                    }} />
                                                {/* {items.phone == viewData ?
                                        <CircularProgress

                                            size={20} /> :
                                        <Refresh onClick={() => {
                                            setViewData(items.phone)
                                            GetUserByEmail_Phone_AdmissionNo_controller(items.email)
                                        }} size={20} />
                                    } */}

                                            </div>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    })}

                </div>

            </Box>

        </>
    )
}