import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../theme/colors';
import { Badge, HarvoxxSchool } from '../../theme/text';
import { LeftBar } from '../../Components/sidebar';
import Header from '../components/header';
import { AllStudentsCard, Card, SearchCard } from '../components/cards';
import { connect } from 'react-redux';
import { User, View_student } from '../../redux/state/action';
import { Avatar, Button, CircularProgress, Divider } from '@mui/material';
import { BaseURL, Notify } from '../../utility';
import { ToastContainer } from 'react-toastify';


const Colors = Color()

const Singlestudent = ({ appState, disp_user, route, disp_View_student }) => {
    const User = appState.User;
    const Data = appState.STUDENTVIEW;
    const [loading, setloading] = React.useState(false)
    const [text, settext] = React.useState("")
    const navigate = useNavigate();

    function EvictRecall(access) {
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "access": access,
            "UUID":Data.uuid
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseURL}admin/evict-student`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let Result = JSON.parse(result)
                console.log(Result.data.hasAccess)

                let filter = User.students.filter(e => e.email == Data.email)
                // get index
                let FindIndex = User.students.findIndex(e => e.email == Data.email)

                if (filter.length > 0) {
                    let newUserData = {
                        ...filter[0],
                        hasAccess: Result.data.hasAccess
                    }
                    User.students.splice(FindIndex, 1, newUserData)
                    disp_user({ ...User })
                    disp_View_student(newUserData)
                    if (access == false) {
                        Notify("Evicted from the program")
                    } else {
                        Notify("Recalled from eviction")
                    }
                    setloading(false)
                } else {
                    setloading(false)
                }


            })
            .catch(error => {
                ;
                setloading(false)
            });
    }


    useEffect(() => {
        console.log(Data)
    }, []);



    return User == null ? navigate("/signin") : (
        <div  >
            <ToastContainer />
            {/* {console.log(User)} */}
            <LeftBar Navigate={navigate} User={User} disp_user={disp_user} page="STUDENTS" />

            <div style={{
                backgroundColor: Colors.white,
                position: "relative",
                width: "80%",
                // height: "100vh",
                left: "20%",
                paddingBottom: 50,
            }} >

                {
                    Data != null &&
                    <>
                        <div style={{
                            marginBottom: 60
                        }} >


                            <div style={{
                                // height: 120,
                                // backgroundColor: "#FBF6E9",
                                // alignItems: "center",
                                justifyContent: "space-evenly",
                                display: "flex",
                                flexDirection: "row",
                                // marginTop: 30,
                                padding: 15
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                }}>
                                    <Badge color="grey" size={13} value="Admission No." />
                                    <Badge color="#000" size={14} value={Data.AdmissionNo} />
                                </div>



                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                    // textAlign:"center"
                                }}>
                                    <Badge color="grey" size={13} value="Program" />
                                    <Badge color="#000" size={14} value={Data.program} />
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                    // textAlign:"center"
                                }}>
                                    <Badge color="grey" size={13} value="Package" />
                                    <Badge color="#000" size={14} value={Data.package} />
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                    // textAlign:"center"
                                }}>

                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                    // textAlign:"center"
                                }}>
                                    <Badge color="grey" size={13} value="Started" />
                                    <Badge color="#000" size={14} value={Data.starting} />

                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "6px 20px",
                                    // textAlign:"center"
                                }}>
                                    <Badge color="grey" size={13} value="Ending" />
                                    <Badge color="#000" size={14} value={Data.ending} />

                                </div>



                            </div>

                            {/* <div style={{ marginBottom: 30 }} >
                                {Data.attendance.map((items, index) => {
                                    return <div key={index} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "6px 20px",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: "6px 20px",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <Badge color="#000" size={12} value={index + 1} />
                                            <div style={{ marginLeft: 10 }}>
                                                <Badge color="#000" size={15} value={"items.name"} />
                                            </div>
                                        </div>
                                        
                                    </div>
                                })}
                            </div> */}

                            <Divider style={{ marginTop: 30 }} />


                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "20px",
                                justifyContent: "space-between",
                                alignItems: "flex-start"
                            }}>
                                <div style={{
                                    // height: 400,
                                    // backgroundColor: "green",
                                    flex: 1,
                                    // alignItems: "center",
                                    display: "flex",
                                    padding: 10,
                                    flexDirection: "column",
                                }}>
                                    <Avatar sx={{ width: 120, height: 120, marginBottom: 3 }} />


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "space-between",
                                        // backgroundColor:"grey",
                                        width: "70%",
                                        marginBottom: 10
                                    }}>
                                        <Badge color="#000" size={12} value={"Name"} />
                                        <div style={{ marginLeft: 10 }}>
                                            <Badge color="#000" size={13} value={Data.name} />
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "space-between",
                                        // backgroundColor:"grey",
                                        width: "70%",
                                        marginBottom: 15
                                    }}>
                                        <Badge color="#000" size={12} value={"Phone number"} />
                                        <div style={{ marginLeft: 10 }}>
                                            <Badge color="#000" size={13} value={Data.phone} />
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        // alignItems: "space-between",
                                        // backgroundColor:"grey",
                                        width: "100%",
                                        marginBottom: 10
                                    }}>
                                        {/* <Badge color="#000" size={12} value={"Email"} /> */}
                                        <div style={{}}>
                                            <Badge color="#000" size={19} value={Data.email} />
                                        </div>
                                    </div>


                                </div>


                                <div style={{
                                    // height: 200,
                                    // backgroundColor: "lightgrey",
                                    flex: 1,
                                    marginTop: 40,
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center"

                                }} >
                                    {
                                        Data.attendance.map((item, index) => {
                                            return <div key={index} style={{
                                                backgroundColor: item.attended == true ? "mediumseagreen" : "maroon",
                                                width: 35, height: 35,
                                                borderRadius: 10, margin: "6px",
                                                opacity: 0.8
                                            }} />
                                        })
                                    }

                                </div>
                            </div>

                            <Divider style={{ marginTop: 30 }} />

                            <div style={{
                                padding: "20px"
                            }}>
                                <Button style={{ margin: 20 }} variant='outlined' > Sign in for a class </Button>
                                {loading == true ?
                                    <CircularProgress size={20} style={{margin: 20}} />
                                    :
                                    <>
                                        {Data.hasAccess == true ?
                                            <Button
                                                onClick={() => { EvictRecall(false) }}
                                                style={{ margin: 20, backgroundColor: "crimson", color: "white" }} variant='outlined' > Evict from program </Button>
                                            :
                                            <Button
                                                onClick={() => { EvictRecall(true) }}
                                                style={{ margin: 20, backgroundColor: "mediumseagreen", color: "white" }} variant='outlined' > Recall to program </Button>
                                        }
                                    </>}
                            </div>
                        </div>

                    </>
                }


            </div>

            {/* SearchCard */}
            {/* <SearchCard /> */}


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
        disp_View_student: (payload) => dispatch(View_student(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Singlestudent);
