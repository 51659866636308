import { BaseURL } from "../../utility";

export async function CreateTaskModel({
    program, packageID, title, deadline, task
}) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "program": program,
        "packageID": packageID,
        "title": title,
        "deadline": deadline,
        "task": task
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}admin/create-assignment`, requestOptions);
        const result_1 = await response.text();
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}