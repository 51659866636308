import { Avatar, Chip, Divider } from "@mui/material"
import { Color } from "../theme/colors"
import { Badge, HarvoxxSchool, Text } from "../theme/text"
import Header from "./Header"

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { HomeIcon } from "../utility/icon";
import { Navigate } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


let Colors = Color()

export function LeftBar({ User, disp_user, Navigate, page }) {
    return <div style={style}>

        <center style={{ padding: 15, position: "absolute", bottom: 0 }}>
            <b style={{ fontSize: 17 }} >
                <span style={{ color: Colors.white }}>Harv</span>
                <span style={{ color: Colors.secondary }}>oxx-</span>
                <span style={{ color: Colors.secondary }}>school </span>
                {/* <b style={{ color: Colors.secondary }}> console </b> */}
            </b>

        </center>

        <center style={{
            marginTop: 28,
        }}  >
            <Avatar
                alt="Remy Sharp"
                // src="https://mui.com/static/images/avatar/1.jpg"
                sx={{ width: 56, height: 56 }} />

            <div style={{ marginTop: 10 }}>
                <Text bold value={User.name.split(" ")[0]} />
                {/* <Text value="DSP-300-C3" /> */}
                <Badge value={User.package} /></div>
        </center>

        <Divider style={{
            marginTop: 19,
            color: Colors.white,
            borderColor: Colors.white,
        }} >
            <Chip style={{
                color: Colors.secondary,
            }} label={User.program} size="small" />
        </Divider>


        {/* <Divider style={{
            marginTop: 29,
            backgroundColor: Colors.white, 
        }} /> */}
        <br />
        <div
            onClick={() => {
                Navigate("/dashboard")
            }}
            className="hover" style={{ padding: 15, cursor: "pointer", backgroundColor: page == "HOME" ? Colors.secondary : Colors.primary }} >
            <Badge value=" Dashboard" color={ page == "HOME" ? Colors.primary : Colors.white } />
        </div>
        <div
            onClick={() => {
                Navigate("/task")
            }}
            className="hover" style={{ padding: 15, cursor: "pointer", backgroundColor: page == "TASK" ? Colors.secondary : Colors.primary }} >
            <Badge value=" Students task" color={ page == "TASK" ? Colors.primary : Colors.white } />
        </div>
        <div
            onClick={() => {
                Navigate("/attendance")
            }}
            className="hover" style={{ padding: 15, cursor: "pointer", backgroundColor: page == "ATTENDANCE" ? Colors.secondary : Colors.primary }} >
            <Badge value=" Attendance" color={ page == "ATTENDANCE" ? Colors.primary : Colors.white } />
        </div>

        <div
            onClick={() => {
                Navigate("/students")
            }}
            className="hover" style={{ padding: 15, cursor: "pointer", backgroundColor: page == "STUDENTS" ? Colors.secondary : Colors.primary }} >
            <Badge value=" Students" color={ page == "STUDENTS" ? Colors.primary : Colors.white } />
        </div>
        {/* <div className="hover" style={{ padding: 15, cursor: "pointer" }} >
            <Badge value="Create announcement" />
        </div> */}

        <div onClick={() => {
            disp_user(null)
            Navigate("/signin")
        }} className="hover" style={{ padding: 15, cursor: "pointer" }} >
            <Badge value="Sign out" />
        </div>


    </div>
}

const style = {
    height: "100%",
    backgroundColor: Colors.primary,
    position: "fixed",
    left: 0,
    top: 0,
    width: "20%",
    // padding:15
}