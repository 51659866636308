import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { CircularProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Notify } from '../../utility';
import { GetUserByEmail_Phone_AdmissionNo_Model } from '../../api-services/users';
export function SearchStudents({
  loading, setloading, text, settext, Navigation, disp_View_student
}) {

  const GetUserByEmail_Phone_AdmissionNo_controller = () => {

    GetUserByEmail_Phone_AdmissionNo_Model(text, setloading)
      .then(response => {
        setloading(false)
        if (response.success == false) {
          Notify(response.message, "error")
        }

        if (response.data) {
          Notify(response.message, "error")
        }
        disp_View_student(response.data)
        Navigation("/single-student")
      })
      .catch(error => {
        Notify("A network error occured", "error")
        setloading(false)
      })
  }

  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <ToastContainer />
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        {/* <MenuIcon /> */}
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Admission No, email or phone"
        inputProps={{ 'aria-label': 'search google maps' }}
        value={text}
        onChange={(value) => {
          settext(value.target.value)
        }}
      />
      {loading == true ? <CircularProgress size={18} style={{ marginRight: 14 }} /> :
        <IconButton
          onClick={() => {
            setloading(true)
            GetUserByEmail_Phone_AdmissionNo_controller()
            // setTimeout(() => {
            //   setloading(false)
            //   Notify("There's no user found", "error")
            //   console.log(Navigation)
            //   Navigation("/single-student")
            // }, 2000);

          }}
          type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      }

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: '10px' }} aria-label="directions">
        <NotificationsNoneIcon />
      </IconButton>
    </Paper>
  );
}