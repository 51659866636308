import './App.css';
import SignUp from './auth/SignUp';
import Signin from './auth/signin';
import { Route, Routes } from 'react-router-dom';
import UsersData from './Screens/UsersData';
import Dashboard from './dashboard/screens/index';
import Attendance from "./dashboard/screens/attendance"
import Task from "./dashboard/screens/task"
import SubmittedTasks from "./dashboard/screens/submitted-task"
import store from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Students from './dashboard/screens/students';
import Singlestudent from './dashboard/screens/singlestudent';

function App() {
  return (
    <Provider store={store().store}>
      <PersistGate loading={null} persistor={store().persistor}>
        <Routes>
          <Route path='/signin' element={<Signin />} />
          <Route path='/' element={<Signin />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/users' element={<UsersData />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/attendance' element={<Attendance />} />
          <Route path='/task' element={<Task />} />
          <Route path='/students' element={<Students />} />
          <Route path='/single-student' element={<Singlestudent />} />
          <Route path='/submitted-task/:task' element={<SubmittedTasks />} />
        </Routes>
      </PersistGate>
    </Provider>

  );
}

export default App;
