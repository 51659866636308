import {
  USER,
  STUDENTVIEW
} from "../state/types";


export const User = (payload) => {
  return {
    type: USER,
    payload

  }

};

export const View_student = (payload) => {
  return {
    type: STUDENTVIEW,
    payload

  };

}; 