import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from "react-modal";
import { Supabase } from "../config/supabase-config";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Color } from '../theme/colors';
import { HarvoxxSchool } from '../theme/text';
import { connect } from 'react-redux';
import { User } from '../redux/state/action';
import { LoginModel } from '../api-services/auth';
import LoadingButton from '@mui/lab/LoadingButton';


const Colors = Color()

const Signin = ({ disp_user, appState }) => {
    const User = appState.User;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");



    useEffect(() => {

    }, []);



    const Login = () => {
        setLoading(true)
        if (email && email.length > 4 && password  ) {
            LoginModel({ email, password })
                .then(response => { 
                    if (response.success == false) {
                        alert(response.message)
                    } else {
                        console.log(response.success)
                        disp_user(response.data)
                        navigate("/dashboard") 
                        // console.log("Nothing======",response)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }



    // return User != null ? navigate("/dashboard") : (
        return (
        <div className='signup-page'>
            {console.log(User)}
            <Header />
            <section className='signup'>
                <div className='left'>
                    <HarvoxxSchool />
                    <h1 style={{ marginTop: 30 }}>
                        <p>
                            Manage students, assign tasks, review tasks, make announcement, create authentication code, and lots more.
                        </p>
                    </h1>

                </div>
                <div className='right' >
                    <div className='form' style={{
                        backgroundColor: Colors.primary,
                        padding: 20
                    }}>

                        <input type="email" name="" id="" placeholder='Email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }} />


                        <br />
                        <input type="password" name="" id="" placeholder='password' value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }} />


                        <LoadingButton loading={loading} variant="contained"
                            loadingPosition="start"
                            load
                            size='small'
                            style={{ cursor: 'pointer', backgroundColor: Colors.secondary, height: 40, width: "100%", marginTop: 30 }}
                            onClick={() => {
                                Login()
                            }}
                        >Login</LoadingButton>




                    </div>
                </div>
            </section>



        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Signin);
