import { BaseURL } from "../../utility";

export async function CreateAttendanceModel({
    packageID, program
}) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "programCode": program,
        "packageID": packageID,
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}admin/create-attendance`, requestOptions);
        const result_1 = await response.text();
        // console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}

export async function UpdateAttendanceStatus({
    status, program, packageID, attendanceID
}) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "status": status,
        "program": program,
        "packageID": packageID,
        "attendanceID": attendanceID
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}admin/update-attendance`, requestOptions);
        const result_1 = await response.text(); 
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        return console.log('error', error);
    }
}