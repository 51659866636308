import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../theme/colors';
import { HarvoxxSchool } from '../../theme/text';
import { LeftBar } from '../../Components/sidebar';
import Header from '../components/header';
import { connect } from 'react-redux';
import { User } from '../../redux/state/action';
import { SubmittedTaskComponent } from '../components/submitted-task-component';
import { useParams } from 'react-router-dom';

const Colors = Color()

const SubmittedTasks = ({ appState, disp_user, route }) => {
    const User = appState.User;
    const navigate = useNavigate();
    const [announcement, setannouncement] = React.useState("")
    const [Task, setTask] = React.useState(null)
    const { task } = useParams();


    useEffect(() => { 
        let Filter = User.Tasks.filter(e => e.id == task)[0];
        setTask(Filter)
    }, []);



    return User == null ? navigate("/signin") : (
        <div  >
            {console.log(Task)}
            <LeftBar Navigate={navigate} User={User} disp_user={disp_user} />

            <div style={{
                backgroundColor: Colors.white,
                position: "relative",
                width: "80%",
                // height: "100vh",
                left: "20%",
                paddingBottom: 50,
            }} >
                <Header />

                <SubmittedTaskComponent page="ATTENDANCE" User={User} disp_user={disp_user} data={Task}  />


            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SubmittedTasks);
