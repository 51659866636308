import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../theme/colors';
import { HarvoxxSchool } from '../../theme/text';
import { LeftBar } from '../../Components/sidebar';
import Header from '../components/header'; 
import { connect } from 'react-redux';
import { User } from '../../redux/state/action';
import { TaskLists } from '../components/task-components';


const Colors = Color()

const Task = ({ appState, disp_user }) => {
    const User = appState.User;
    const navigate = useNavigate();
    const [announcement, setannouncement] = React.useState("")




    useEffect(() => {

    }, []);



    return User == null ? navigate("/signin") : (
        <div  >
            {/* {console.log(User)} */}
            <LeftBar Navigate={navigate} User={User} disp_user={disp_user} page="TASK" />

            <div style={{
                backgroundColor: Colors.white,
                position: "relative",
                width: "80%",
                // height: "100vh",
                left: "20%",
                paddingBottom: 50,
            }} >
                <Header />

                <TaskLists page="ATTENDANCE" User={User} disp_user={disp_user} data={announcement} setData={setannouncement} />


            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Task);
