import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { colors } from '@mui/material';
import { Color } from '../../theme/colors';
import { CreateAnnouncementModel } from '../../api-services/announcement';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const notify = (message) => {
    // toast("Default Notification !");

    toast.success(message, {
        position: toast.POSITION.TOP_Left
    });

    // toast.error("Error Notification !", {
    //     position: toast.POSITION.TOP_LEFT
    // });

    // toast.warn("Warning Notification !", {
    //     position: toast.POSITION.BOTTOM_LEFT
    // });

    // toast.info("Info Notification !", {
    //     position: toast.POSITION.BOTTOM_CENTER
    // });

    // toast("Custom Style Notification with css class!", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: 'foo-bar'
    // });
};


const Colors = Color()
export function CreateAnnouncement({ User, data, setData, disp_user }) {

    const Create = () => {

        CreateAnnouncementModel({
            message: data, program: User.program, packageID: User.packageID
        })
            .then(res => {
                if (res.success == true) {
                    setData("")

                    User.Announcements.push(res.data)
                    console.log(User.Announcements.length)
                    disp_user(User)
                    notify("Announcement sent successfully")
                }
            })
            .catch(error => {
                console.log(error)
            })
    }


    return (
        <Card sx={{ maxWidth: 345, my: 3 }}>
            <ToastContainer />
            <textarea
                value={data}
                onChange={(e) => {
                    setData(e.target.value);
                }}
                placeholder='Create an announcement for all your students'
                style={{
                    height: 100,
                    width: "80%",
                    border: "none",
                    padding: 20,
                    outline: "none",
                    resize: "none",

                }} ></textarea>
            <CardActions>
                {/* <Button size="small">Share</Button> */}
                <Button

                    onClick={() => {
                        if (data.length > 0) {
                            Create()
                        }
                    }}
                    style={{ backgroundColor: data.length < 1 ? "lightgrey" : Colors.primary, width: 300, padding: "10px 0px", color: "white" }}  >
                    Send annoncement</Button>
            </CardActions>
        </Card>
    );
}