import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { HomeIcon, Announcement, TaskIcon, AttendanceIcon } from '../../utility/icon';
import { Badge } from '../../theme/text';
import { Button, CardActions, CircularProgress, Divider, Fade, Modal, Typography } from '@mui/material';
import { CreateAnnouncement } from './create-announcement';
import { GroupOutlined, Label } from '@mui/icons-material';
import { Notify, formatDate } from '../../utility';
import { Color } from '../../theme/colors';
import { CreateAttendance } from './creat-attendance';
import { LoadingButton } from '@mui/lab';
import { UpdateAttendanceStatus } from '../../api-services/attendance';
import { ToastContainer } from 'react-toastify';
import { GetUser } from '../../api-services/users';
import QRCode from "react-qr-code";
import Backdrop from '@mui/material/Backdrop';
import { CreateTaskModel } from '../../api-services/task';
import { useNavigate } from 'react-router';

const Colors = Color()




export function SubmittedTaskComponent({ User, data, disp_user }) {
    let [viewData, setViewData] = React.useState(null)
    let [loading, setLoading] = React.useState(false)
    let [date, setDate] = React.useState("")
    let [title, setTitle] = React.useState("")
    const navigate = useNavigate();


    function Card({ item }) {
        return (

            <div
                onClick={() => {
                    setViewData(item)
                }}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: item.isActive ? "#f9f9f9" : "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 13px",
                    marginBottom: 6,
                    cursor: "pointer"

                }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "green",
                    flex: 2
                }}>
                    <Badge value={item.name} size={12} color="grey" />
                    <Badge value={item.admissionNo} size={12} color="#000" />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "green",
                    flex: 1
                }}>
                    {/* <Badge value={formatDate(item.)} size={12} color="red" /> */}

                </div>
            </div>
        );
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    function CreateTask() {
        setLoading(true)
        CreateTaskModel({ program: User.program, packageID: User.packageID, title, deadline: date, task: data })
            .then(response => {
                GetUser(User.UUID)
                    .then(response => {
                        setLoading(false)
                        disp_user(response.data)
                        Notify("Task created")
                    })
                    .catch(error => {
                        setLoading(false)
                        Notify("An error occured", "error")
                    })

            })
            .catch(error => {
                Notify("An error occured", "error")
                setLoading(false)
            })
    }

    return (
        <>
            <ToastContainer />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        width: 200,
                        height: 108,
                    },
                    justifyContent: "space-between",
                    marginBottom: 30

                }}
            >

                <div style={{
                    // height: 500,
                    width: "57%",
                    // backgroundColor: "#F2F1F3",
                    position: "relative",
                    left: 20,
                    top: 20,
                    padding: 10,

                }}>
                    {/* <Badge value="Task" color="grey" /> */}

                    <div style={{
                        marginTop: 20,
                        // backgroundColor: "#000",
                    }}>

                        {
                            viewData != null ?
                                <>
                                    <Divider style={{ marginTop: 30 }} />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // marginTop: 30,
                                        padding: 15
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "6px 20px",
                                        }}>
                                            <Badge color="grey" size={13} value="Created" />
                                            <Badge color="#000" size={14} value={formatDate(data && data.date)} />
                                        </div>



                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "6px 20px",
                                            textAlign: "center"
                                        }}>
                                            <Badge color="grey" size={13} value="Submitted" />
                                            <Badge color="#000" size={14} value={data && data.submitted.length} />

                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "6px 20px",
                                            // textAlign:"center"
                                        }}>
                                            <Badge color="grey" size={13} value="Task status" />
                                            <Badge color="#000" size={14} value={data && data.isActive == true ? "ACTIVE" : "INACTIVE"} />
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "6px 20px",
                                            // textAlign:"center"
                                        }}>
                                            <Badge color="#000" size={14} value={formatDate(data && data.deadline)} />
                                            <Badge color="grey" size={13} value="Deadline" />
                                        </div>


                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "16px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="#000" size={14} value={viewData.admissionNo} />
                                        <Badge color="grey" size={13} value={viewData.name} />
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "16px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="#000" size={14} value="Link" />
                                        <div style={{cursor:"pointer"}} onClick={() => {  
                                            window.open(viewData.link, '_blank');
                                        }} >

                                            <Badge color="grey" size={13} value={viewData.link} />

                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "16px 20px",
                                        // textAlign:"center"
                                    }}>
                                        <Badge color="#000" size={14} value="Feedback" />
                                        <Badge color="grey" size={13} value={viewData.feedback} />
                                    </div>



                                </> :

                                <>
                                    <center style={{
                                        padding: "40px 0px"
                                    }}>
                                        <Badge value={data && data.submitted.length} color="#000" size={26} />
                                        <Badge value={"Submitted"} size={13} color="#000" />
                                    </center>
                                </>
                        }

                    </div>
                </div>



                {/* timetable */}
                <div style={{
                    // height: 130,
                    width: "30%",
                    // backgroundColor: "#F2F1F3",
                    position: "relative",
                    right: 20,
                    top: 20,
                    borderRadius: 10,

                }} >
                    {
                        // console.log(User)
                        data && data.submitted.map((item, index) => {
                            return <Card item={item} />
                        })
                    }
                </div>

            </Box >




        </>
    )
}