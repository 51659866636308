import {
    USER,
    STUDENTVIEW
} from '../state/types'


const initialState = {
    User: null,
    STUDENTVIEW: null
}





const reducer = (state = initialState, action) => {
    switch (action.type) {

        case USER:
            return {
                ...state,
                User: action.payload,
            }
        case STUDENTVIEW:
            return {
                ...state,
                STUDENTVIEW: action.payload

            }
        default: return state
    }
}

export default reducer