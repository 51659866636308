import { BaseURL, Notify } from "../../utility";

export async function GetUser(UUID) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${BaseURL}admin/get-admin-data/${UUID}`, requestOptions);
        const result_1 = await response.text();
        // console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        Notify("A network error occured", "error")
    }
}

export async function HD(payload, setloading) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "payload": payload
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`https://harvoxx-school-server.vercel.app/api/v1/user/getUserByEmail_phone_admissionNo`, requestOptions);
        const result_1 = await response.text();
        console.log(result_1)
        let Data = JSON.parse(result_1)
        return Data;
    } catch (error) {
        Notify("A network error occured", "error")
        setloading(false);
        console.log(error)
        return error
    }
}

export async function GetUserByEmail_Phone_AdmissionNo_Model(payload, setloading) {
    console.log(payload)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "payload": payload
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch("https://harvoxx-school-server.vercel.app/api/v1/user/getUserByEmail_phone_admissionNo", requestOptions);
        const result_1 = await response.text(); 
        let Data = JSON.parse(result_1)
        console.log(Data)
        return Data;
    } catch (error) {
        Notify("A network error occured", "error")
        setloading(false);
        console.log(error)
        return error
    }
}