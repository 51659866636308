import { Color } from "./colors"

const Colors = Color()
export function HarvoxxSchool() {
    return <b style={{ fontSize: 30 }} >
        <b style={{ color: Colors.primary }}>Harv</b>
        <b style={{ color: Colors.secondary }}>oxx-</b>
        <b style={{ color: Colors.primary }}>school </b>
        <b style={{ color: Colors.primary }}> Admin </b>
        <b style={{ color: Colors.primary }}> Console </b>


    </b>
}

export function Text({
    bold, value
}) {
    return <p style={{
        fontWeight: bold ? 900 : 300,
        color: bold ? Colors.white : Colors.secondary,
        fontSize: bold ? 20 : 14,
    }} >
        {value}
    </p>
}


export function Badge({
    value, size, color
}) {
    return <p style={{
        fontWeight:500,
        color: color ? color : "lightgrey",
        fontSize:size ? size : 15,
        marginTop:6
    }} >
        {value}
    </p>
}

let style = {

}