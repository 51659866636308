import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../theme/colors';
import { HarvoxxSchool } from '../../theme/text';
import { LeftBar } from '../../Components/sidebar';
import Header from '../components/header';
import { AllStudentsCard, Card, SearchCard } from '../components/cards';
import { connect } from 'react-redux';
import { User, View_student } from '../../redux/state/action';


const Colors = Color()

const Students = ({ appState, disp_user, disp_View_student }) => {
    const User = appState.User;
    const navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [announcement, setannouncement] = React.useState("")
    const Data = appState.STUDENTVIEW;



    useEffect(() => {

    }, []);



    return User == null ? navigate("/signin") : (
        <div  >
            {/* {console.log(User)} */}
            <LeftBar Navigate={navigate} User={User} disp_user={disp_user} page="STUDENTS" />

            <div style={{
                backgroundColor: Colors.white,
                position: "relative",
                width: "80%",
                // height: "100vh",
                left: "20%",
                paddingBottom: 50,
            }} >
                <Header />

                <AllStudentsCard
                    page="ATTENDANCE"
                    User={User} disp_user={disp_user}
                    disp_View_student={disp_View_student}
                    Navigation={navigate} 
                    Data={Data} 
                />


            </div>

            {/* SearchCard */}
            {/* <SearchCard /> */}


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_user: (payload) => dispatch(User(payload)),
        disp_View_student: (payload) => dispatch(View_student(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Students);
